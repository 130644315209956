
import {
  format as percentFormat,
  convertToPercentage,
  z
} from "@/assets/script/utils/health.util"

import { watch, computed, nextTick, onMounted, onBeforeUnmount } from "vue"
import echarts from "echarts"
import { dateToTimeList } from "@/assets/script/utils/date.util"
import { ECHARTS_GRID } from "@/views/report/script/constant"
import { useI18n } from "vue-i18n"
import { WS_HOST } from "@/assets/script/constant/config.constant"
import { SignInVo, UserService } from "@/assets/script/service/user.service"
import { sleep } from "@/assets/script/utils/heighten.util"

import { HealthRealtimeData } from "../realtime/constants"
import { ref, onDeactivated } from "vue"
import { useRouter } from "vue-router"
interface Props {
  yAxis: number[]
  startTime: number
}

export default {
  setup(props: Props) {
    const router = useRouter()
    const { t } = useI18n()

    const uid = router.currentRoute.value.query.uid || "" // 获取 phone 参数值，如果没有则默认为空字符串
    const healthId = router.currentRoute.value.query.healthId || "" // 获取 password 参数值，如果没有则默认为空字符串

    // const healthId = "5C002E00085031364233382060040000" //测试ID

    const healthRealtimeData = ref<HealthRealtimeData | undefined>(undefined)
    let yAxisData: any[] = [] // 在外部作用域中声明并初始化 yAxisData 变量
    yAxisData = []
    let data: any[] = []

    let dataIndex = 0

    const xAxisLength = 200

    const count = ref(0)

    let webSocket: WebSocket
    let timer: ReturnType<typeof setTimeout>

    let echartRef: HTMLDivElement
    let echartsDom: echarts.ECharts
    const setRef = (el: HTMLDivElement) => (echartRef = el)

    let sliderVisible = false

    let abnormalFlag = false

    let disconnectFlag = false

    // 心跳 timer
    let heartTimer: any = null

    const option = computed(() => {
      return {
        animation: true,
        grid: {
          left: 0,
          top: 20,
          bottom: 20, // 调整底部间距，使得 x 轴标签不占据高度空间
          right: 0,
          containLabel: false
        },
        dataZoom: [
          {
            type: "inside",
            start: 0, //数据窗口范围的起始百分比,表示1%
            end: 100 //数据窗口范围的结束百分比,表示15%坐标
          },
          {
            type: "slider", //slider表示有滑动块的，
            show: sliderVisible,
            left: 0,
            right: 10,
            bottom: 20,
            brushSelect: true,
            moveHandleSize: 10,
            start: 0,
            end: 15
          }
        ],
        tooltip: {
          show: false,
          trigger: "axis"
        },
        xAxis: {
          type: "category",
          boundaryGap: false,
          data: Array.from({ length: xAxisLength }, (_, i) => i + 1),
          show: false,
          axisLabel: {
            show: false
          },
          axisLine: {
            show: true, // 轴线
            lineStyle: {
              color: "#999", // 刻度线颜色
              type: "dashed" // 刻度线类型为虚线
            }
          },
          axisTick: {
            show: false // 隐藏坐标轴刻度
          },
          axisPointer: {
            show: false // 隐藏坐标轴指示器
          }
        },
        yAxis: {
          type: "value",
          boundaryGap: false,
          max: 100,
          min: -200,
          splitLine: {
            lineStyle: {
              type: "dashed",
              color: "gray"
            }
          },
          axisLine: {
            show: false
          },
          axisTick: {
            show: false
          },
          axisLabel: {
            show: false
          }
        },
        series: [
          {
            type: "line",
            stack: "Total",
            smooth: true,
            lineStyle: {
              width: 0
            },
            showSymbol: false,
            areaStyle: {
              origin: "start",
              opacity: 0.8,
              color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                {
                  offset: 0,
                  color: "rgb(128, 255, 165)"
                },
                {
                  offset: 1,
                  color: "rgb(1, 191, 236)"
                }
              ])
            },
            emphasis: {
              focus: "series"
            },
            data: data
          }
        ]
      }
    })

    function init() {
      data = []
      yAxisData = []
      healthRealtimeData.value = undefined
      // 添加足够的空数据，以确保图表一开始就从最右边开始显示
      for (let i = 0; i < xAxisLength; i++) {
        data.push(null)
      }
      dataIndex = 0 // 清除波形2
      count.value = 0 //清空进度条
      sliderVisible = false
      abnormalFlag = false
      disconnectFlag = false
      echartsDom.setOption(option.value as echarts.EChartOption)
    }

    function startHealthTest() {
      // console.log("重新开始健康测试")
      init()
    }

    // 定义一个函数来等待 WebSocket 连接准备就绪
    const waitForWebSocketConnection = async (
      webSocket: WebSocket,
      maxAttempts = 20,
      interval = 500
    ): Promise<void> => {
      let attempts = 0
      while (
        webSocket.readyState !== WebSocket.OPEN &&
        attempts < maxAttempts
      ) {
        await sleep(interval)
        attempts++
      }
      if (webSocket.readyState !== WebSocket.OPEN) {
        throw new Error("WebSocket 连接超时")
      }
    }

    const sendRealtime = (s = false) => {
      if (webSocket.readyState !== 1) return
      webSocket.send(
        JSON.stringify({
          method: "health",
          uid: uid,
          deviceId: healthId,
          switch: s
        })
      )
    }

    onMounted(() => {
      echartsDom = echarts.init(echartRef)
      echartsDom.setOption(option.value as echarts.EChartOption)
    })

    // 定义一个函数来处理下一步逻辑
    const next = async (): Promise<void> => {
      try {
        // 等待 WebSocket 连接准备就绪
        await waitForWebSocketConnection(webSocket)
        startHealthTest()
        // 在 WebSocket 准备好后发送数据
        sendRealtime(true)
      } catch (error) {
        console.error(error)
      }
    }

    const ready = async () => {
      await next()
    }

    // 清除❤️跳 timer
    const clearHeartPackTimer = () => {
      if (heartTimer) {
        clearInterval(heartTimer)
        heartTimer = null
      }
    }

    function connectWebSocket() {
      webSocket = new WebSocket(WS_HOST)
      webSocket.onmessage = (result) => {
        const webSocketData = JSON.parse(result.data)
        if (webSocketData.dataKey === "open") {
          startHealthTest()
        }
        if (webSocketData.dataKey == "realtime") {
          if (count.value == 0) {
            count.value = 1
          }
          // console.log("count=" + count.value)
          // clearTimeout(timer)
          const wsData: HealthRealtimeData = JSON.parse(result.data)

          if (wsData.dataKey === "realtime") {
            healthRealtimeData.value = wsData
            yAxisData.push(...wsData.acData)
            count.value = wsData.percentage
          }
        }
        // 出现异常过程
        if (webSocketData.dataKey === "abnormal") {
          count.value = 31
          abnormalFlag = true
        }
        // 采集数据异常
        if (webSocketData.dataKey === "disconnect") {
          count.value = 30
          disconnectFlag = true
        }
        if (webSocketData.dataKey === "finish") {
          sliderVisible = true
          echartsDom.setOption({
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: Array.from({ length: yAxisData.length }, (_, i) => i + 1),
              show: false,
              axisLabel: {
                show: false
              },
              axisLine: {
                show: true, // 轴线
                lineStyle: {
                  color: "#999", // 刻度线颜色
                  type: "dashed" // 刻度线类型为虚线
                }
              },
              axisTick: {
                show: false // 隐藏坐标轴刻度
              },
              axisPointer: {
                show: false // 隐藏坐标轴指示器
              }
            },
            dataZoom: [
              {
                type: "inside",
                start: 0, //数据窗口范围的起始百分比,表示1%
                end: 16 //数据窗口范围的结束百分比,表示15%坐标
              },
              {
                type: "slider", //slider表示有滑动块的，
                show: sliderVisible,
                left: 0,
                right: 10,
                bottom: 20,
                brushSelect: true,
                moveHandleSize: 10,
                start: 0,
                end: 100
              }
            ],
            series: [
              {
                name: "波形图",
                data: yAxisData
              }
            ]
          } as echarts.EChartOption)
          // console.log("healthReportId=" + webSocketData.healthReportId)
          if (webSocketData.healthReportId != "") {
            router.push({
              name: "healthReport",
              params: {
                healthReportId: webSocketData.healthReportId,
                healthReportFalg: "1"
              }
            })
          }
        }
        // timer = setTimeout(() => {
        //   healthRealtimeData.value = undefined
        // }, 3000)
      }
      webSocket.onopen = () => {
        clearHeartPackTimer()
        // 开始心跳检测
        heartTimer = setInterval(() => {
          if (webSocket.readyState === 1) {
            // 发送心跳消息
            webSocket.send(JSON.stringify({ method: "pong" }))
          } else {
            // 如果连接已断开，则停止心跳检测并重新连接
            clearInterval(heartTimer)
            setTimeout(connectWebSocket, 5000) // 重新连接的间隔时间
          }
        }, 9900)
      }

      webSocket.onclose = function(e) {
        clearHeartPackTimer()
        let reconnectAttempts = 0
        const maxReconnectAttempts = 100
        const reconnect = () => {
          if (reconnectAttempts < maxReconnectAttempts) {
            setTimeout(() => {
              reconnectAttempts++
              connectWebSocket()
              ready()
            }, Math.min(3000 * Math.pow(2, reconnectAttempts), 30000)) // 指数退避
          } else {
            console.error("Max reconnect attempts reached")
          }
        }
        reconnect()
      }
    }
    connectWebSocket()
    ready()

    const intervalId = setInterval(function() {
      // console.log("yAxisData:", yAxisData)
      if (
        yAxisData.length > 0 &&
        sliderVisible === false &&
        abnormalFlag == false &&
        disconnectFlag == false
      ) {
        data.push(yAxisData[dataIndex])
        dataIndex++
        // console.log("Added data:", data)
        data.shift()
        echartsDom.setOption({
          series: [
            {
              name: "波形图",
              data: data
            }
          ]
        } as echarts.EChartOption)
      }
    }, 25)

    // 在组件卸载前清除定时器
    onBeforeUnmount(() => {
      if (intervalId) {
        clearInterval(intervalId)
      }
    })

    return {
      setRef,
      percentFormat,
      convertToPercentage,
      z,
      healthRealtimeData,
      count,
      startHealthTest,
      abnormalFlag,
      disconnectFlag
    }
  }
}
